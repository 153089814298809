.container {
  padding: 0 132px;
  display: flex;
  flex-direction: column;
  /* color: #fff; */
  margin-bottom: 50px;
}
.main {
  display: flex;
  justify-content: space-between;
  gap: 48px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
  width: 70%;
}
.table-container .center {
  text-align: center;
  /* display: flex; */
  padding-left: 10px;
}
.table-container table {
  width: 100%;

  /* border-collapse: collapse; */
  /* border: 1px solid #ccc; */
  /* color: #fff; */
  background-color: transparent;
}
.table-container th {
  font-weight: 500;
  font-size: 22px;
}
.table-container th,
.table-container td {
  /* border: 1px solid #ccc; */
  text-align: left;
  padding-top: 32px;
}
.table-container .center div {
  text-align: center;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  gap: 10px;
  align-items: center;
  height: 100%;
}
.center svg {
  cursor: pointer;
}
.table-container .img {
  min-width: 100px;
  height: 100px;
  position: relative;
  max-width: 157px;
}
.table-container .img img {
  width: 100%;
  height: 100%;
}
.table-container .img svg {
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: #f8c46e;
  border-radius: 15px;
  cursor: pointer;
}
.table-container td {
  font-size: 18px;
  line-height: 28.6px;
}
.wider-cell {
  max-width: 392px;
}
.name-container {
  display: flex;
  flex-direction: column;
}
.name-container .size {
  color: #c8c5c5;
  font-style: italic;
  font-weight: 600;
}
.table-container td.wider-cell {
  display: flex;
  gap: 30px;
  align-items: center;
}
.price {
  width: 70px;
}
.payments {
  max-width: 310px;
  width: 30%;
  min-width: 210px;
  background-color: #2a2a2a;
  padding: 15px 30px 40px 30px;
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  height: fit-content;

  .summary {
    font-size: 22px;
    line-height: 34.32px;
    font-weight: 500;
  }
  .details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #afafaf;

    .details {
      display: flex;
      justify-content: space-between;
      color: #afafaf;

      .price {
        color: #f8c46e;
        font-size: 16px;
        line-height: 19.36px;
      }
    }
  }
  .total {
    display: flex;
    color: #afafaf;
    font-size: 20px;
    line-height: 24.2px;
    font-weight: 700;
    justify-content: space-between;

    .price {
      color: #f8c46e;
    }
  }
  .button {
    background-color: #f8c46e;
    padding: 0 24px;
    height: 50px;
    border-radius: 24px;
    font-size: 16px;
    line-height: 22.88px;
  }
}
.warning {
  text-align: center;
  margin-top: 100px;
  font-style: italic;
  font-size: 24px;
  width: 100%;
}
@media (max-width: 1250px) {
  .table-container th {
    font-size: 18px;
  }
  .table-container td {
    font-size: 14px;
  }
  .payments {
    .summary {
      font-size: 18px;
    }
    .details-container .details {
      font-size: 14px;
      .price {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 1000px) {
  .container {
    padding: 0 48px;
  }
  .center {
    min-width: 110px;
  }
}
@media (max-width: 900px) {
  .container {
    padding: 0 32px;
  }
  .main {
    flex-direction: column;
  }
  .table-container {
    width: 100%;
  }
  .payments {
    width: 100%;
    max-width: 100%;
    .button {
      width: 50%;
      min-width: 200px;
      margin: 0 auto;
    }
  }
  .table-container td.wider-cell .img {
    min-width: 0;
    width: 30%;
    height: fit-content;
  }
}
/* Media query for responsiveness */
@media (max-width: 600px) {
  .heading {
    font-size: 22px;
  }
  td.center {
    min-width: 0;
  }
  .table-container th {
    font-size: 16px;
  }
  .table-container td.wider-cell .img {
    display: none;
  }
}
